var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-upload", {
    staticClass: "image-uploader",
    attrs: {
      action: "".concat(_vm.path, "/fileUploadAndDownload/upload"),
      headers: {
        "x-token": _vm.token
      },
      "show-file-list": false,
      "on-success": _vm.handleImageSuccess,
      "before-upload": _vm.beforeImageUpload,
      multiple: false
    }
  }, [_vm.imageUrl ? _c("img", {
    staticClass: "image",
    attrs: {
      src: _vm.path + _vm.imageUrl
    }
  }) : _c("i", {
    staticClass: "el-icon-plus image-uploader-icon"
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };